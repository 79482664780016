import axios, {AxiosRequestConfig} from 'axios';
import {AnyAction} from 'redux';
import {multiClientMiddleware} from 'redux-axios-middleware';
import {ThunkDispatch} from 'redux-thunk';
import config from '../config';
import {State} from '../state';

export interface AxiosAction<T = any> {
  payload: {
    client: Client;
    request: AxiosRequestConfig
  };
  onSuccess?: (responseData: T) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => void;
  type: string;
}

export interface AxiosSuccessAction<T, PA = void> {
  payload: {
    data: T;
  };
  meta: {
    previousAction: PA;
  };
  type: string;
}

export interface AxiosDispatch<T = AnyAction> {
  (action: T): T;
  <R>(action: AxiosAction): Promise<R>;
}

interface InterceptorParams {
  getState: () => State;
  dispatch: any;
  getSourceAction: any;
}

const clients = {
  backend: {
    client: axios.create({
      baseURL: config.backend.url,
      responseType: 'json'
    })
  },
  backendAdmin: {
    client: axios.create({
      baseURL: (config.backend.url || '').replace(/api$/, 'admin'),
      responseType: 'json'
    })
  },
  customerProfile: {
    client: axios.create({
      baseURL: config.customerProfile.url + '/api',
      responseType: 'json'
    })
  },
  updateServer: {
    client: axios.create({
      baseURL: config.updateServer.url
    })
  },
  workManagement: {
    client: axios.create({
      baseURL: config.workManagement.url + '/api'
    })
  }
};

export type Client = keyof typeof clients;

export const bearerTokenInterceptor = (
    {getState, dispatch, getSourceAction}: InterceptorParams,
    interceptorConfig: any
) => {
  const authState = getState().auth;

  return new Promise((resolve, reject) => {

    if (authState.authenticated ) {
      authState.updateTokenFunction(config.auth.accessTokenValidity)
          .success(() => {
            interceptorConfig.headers.common.authorization = `Bearer ${authState.getTokenFunction()}`;
            return resolve(interceptorConfig);
          })
          .error(() => {
            return reject();
          });
    } else {
      return resolve(interceptorConfig);
    }
  });
};

export const axiosMiddleware = multiClientMiddleware(clients, {
  interceptors: {
    request: [
      bearerTokenInterceptor
    ]
  }
});
