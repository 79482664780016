import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filter } from 'ramda';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import messages from '../../customers/translations';
import { State } from '../../state';
import { Feature, Setting, SettingWithFeatureCode } from '../state';

export type SettingValueLookup = (settingCode: string) => string | null;

export interface SettingColumn {
  value: SettingValueLookup;
  title: string;
}

interface OwnProps {
  enabled: boolean;
  featureCode: string;
  settingColumns: SettingColumn[];
}

export interface Props extends OwnProps {
  feature: Feature;
  settings: Record<string, Setting>;
}

export const FeatureComponent = (props: Props) => {
  const { feature, settings } = props;

  const { formatMessage } = useIntl();

  const [expanded, setExpanded] = React.useState(false);

  const settingColumns = props.settingColumns.concat([{
    title: 'Default Value',
    value: (settingCode) => settings[settingCode].defaultValue
  }]);

  function handleChange() {
    if (props.enabled) {
      setExpanded(!expanded);
    }
  }

  return (
    <Accordion
      onChange={handleChange}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={props.enabled ? <ExpandMoreIcon /> : <></>}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={props.enabled}
              readOnly={true}
              value={props.enabled}
              color="primary"
            />
          )}
          label={feature.name}
        />
      </AccordionSummary>
      {feature.description ? (
        <AccordionDetails>
          <Typography variant="body2" color="textSecondary" component="p">{feature.description}</Typography>
        </AccordionDetails>
      ) : null}
      {/* Don't show settings for disabled features */}
      {props.enabled ? (
        <AccordionDetails>
          {feature.settings && feature.settings.length > 0 ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{formatMessage(messages['customers.setting.name'])}</TableCell>
                    {settingColumns.map(column => <TableCell key={column.title}>{column.title}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feature.settings.map(settingCode => (
                    <TableRow key={settingCode}>
                      <TableCell>
                        <Tooltip title={settings[settingCode].description || ''} placement="right">
                          <span>{settings[settingCode].name}</span>
                        </Tooltip>
                      </TableCell>
                      {settingColumns.map(column => (
                        <TableCell key={[settingCode, column.title].join('.')}>
                          {column.value(settingCode)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography variant="body2" color="textSecondary" component="p">
              {feature.name} {formatMessage(messages['customers.setting.hasNo'])}
            </Typography>
          )}
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};

const filterByFeature = (featureCode: string, settings: Record<string, SettingWithFeatureCode>) =>
  filter(s => s.featureCode === featureCode, settings);

const mapStateToProps = (state: State, props: OwnProps) => ({
  feature: state.features.byCode[props.featureCode],
  settings: filterByFeature(props.featureCode, state.features.settingsByCode)
});

export default connect(mapStateToProps)(FeatureComponent);
