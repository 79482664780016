import {combineReducers} from 'redux';
import {auth} from './auth/reducer';
import {customerFeatures} from './customer-features/reducers';
import { customerRelationship } from './customer-relationship/reducers';
import {customers} from './customers/reducers';
import {features} from './features/reducers';
import {handhelds} from './handhelds/reducers';
import {handheldReleases} from './handhelds/releases/reducers';
import {ringReleases} from './handhelds/ring-releases/reducers';
import {handheldUpdates} from './handhelds/updates/reducers';
import {hubs} from './hubs/reducers';
import {locations} from './locations/reducers';
import {user} from './me/reducer';
import {notifications} from './notifications/reducer';
import {preferences} from './preferences/reducers';

export const reducer = combineReducers({
  auth,
  customerFeatures,
  customerRelationship,
  customers,
  features,
  handheldReleases,
  handheldUpdates,
  handhelds,
  hubs,
  locations,
  notifications,
  preferences,
  ringReleases,
  user
});
