import checkitTheme from '@checkit/react-components/checkit-theme';
import {blue} from '@material-ui/core/colors';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const {palette, ...rest} = checkitTheme;
const {primary, ...paletteRest} = palette;

export const officeTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue['900']
    },
    ...paletteRest
  },
  ...rest
});
