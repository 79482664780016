import { FormPage } from '@checkit/react-components';
import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SettingsIcon from '@material-ui/icons/Settings';
import { indexBy, pathOr, prop } from 'ramda';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getCustomerFeatures } from '../../customer-features/actions';
import { CustomerSetting } from '../../customer-features/state';
import { getFeatures } from '../../features/actions';
import FeatureComponent, { SettingValueLookup } from '../../features/component/FeatureComponent';
import { Features } from '../../features/state';
import { AxiosDispatch } from '../../middleware/axios';
import { State } from '../../state';
import messages from '../translations';

interface Props extends RouteComponentProps<{ customerId: string }> {
  customerFeatures: string[];
  customerSettings: string[];
  customerSettingsByCode: Record<string, CustomerSetting>;
  features: Features;
  dispatch: AxiosDispatch;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    wrapper: {
      width: '100%'
    }
  })
);

export const CustomerFeatures = (props: Props) => {
  const { customerFeatures, customerSettingsByCode, customerSettings, features, dispatch } = props;
  const customerId = props.match.params.customerId;

  const indexedCustomerSettings = indexBy(
    prop('settingCode'),
    customerSettings.map(code => customerSettingsByCode[code])
  );

  useEffect(() => {
    dispatch(getCustomerFeatures(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    dispatch(getFeatures(true));
  }, [dispatch]);

  const customerValue: SettingValueLookup = (settingCode) => {
    const customerSettingValue = pathOr(null, [settingCode, 'value'], indexedCustomerSettings);

    if (customerSettingValue === pathOr(null, [settingCode, 'defaultValue'], features.settingsByCode)) {
      return null;
    }

    return customerSettingValue;
  };

  const classes = useStyles();

  const { formatMessage } = useIntl();

  return (
    <FormPage pageTitle={formatMessage(messages['customers.features'])} pageIcon={<SettingsIcon />}>
      <div className={classes.wrapper}>
        {features.allCodes.map(featureCode => {
          return (
            <FeatureComponent
              key={featureCode}
              enabled={customerFeatures.includes(featureCode)}
              featureCode={featureCode}
              settingColumns={[{
                title: formatMessage(messages['customers.customer']),
                value: customerValue
              }]}
            />
          );
        })}
      </div>
    </FormPage>
  );
};

const mapStateToProps = (state: State, props: Props) => ({
  customerFeatures: state.customerFeatures.features[props.match.params.customerId] || [],
  customerSettings: state.customerFeatures.settingsByCustomerId[props.match.params.customerId] || [],
  customerSettingsByCode: state.customerFeatures.settingsByCode,
  features: state.features
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeatures);
