import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';

const GET_CHILDREN = 'CUSTOMERS.GET_CHILDREN';
const GET_CHILDREN_SUCCESS = GET_CHILDREN + '_SUCCESS';
export const ADD_CHILDREN = 'CUSTOMERS.ADD_CHILDREN';
export const ADD_CHILDREN_SUCCESS = ADD_CHILDREN + '_SUCCESS';
const GET_PARENTS = 'CUSTOMERS.GET_PARENTS';
const GET_PARENTS_SUCCESS = GET_PARENTS + '_SUCCESS';

export interface CustomerRelationshipDto {
  customerId: string;
}

export interface GetChildren extends AxiosAction {
  customerId: string;
  type: typeof GET_CHILDREN;
}

export interface GetChildrenSuccessAction
    extends AxiosSuccessAction<CustomerRelationshipDto[], GetChildren> {
  type: typeof GET_CHILDREN_SUCCESS;
}

export interface AddChildren extends AxiosAction {
  customerId: string;
  type: typeof ADD_CHILDREN;
}

export interface AddChildrenSuccessAction extends AxiosSuccessAction<CustomerRelationshipDto[], GetChildren> {
  type: typeof ADD_CHILDREN_SUCCESS;
}

export interface GetParents extends AxiosAction {
  customerId: string;
  type: typeof GET_PARENTS;
}

export interface GetParentsSuccessAction
    extends AxiosSuccessAction<CustomerRelationshipDto[], GetParents> {
  type: typeof GET_PARENTS_SUCCESS;
}

export function getChildren(customerId: string): GetChildren {
  return {
    customerId,
    payload: {
      client: 'customerProfile',
      request: {
        method: 'GET',
        url: `/customers/${customerId}/children`
      }
    },
    type: GET_CHILDREN
  };
}

export function addChildren(customerId: string, children: CustomerRelationshipDto[]): AddChildren {
  return {
    customerId,
    payload: {
      client: 'customerProfile',
      request: {
        data: children,
        method: 'PUT',
        url: `/customers/${customerId}/children`
      }
    },
    type: ADD_CHILDREN
  };
}

export function getParents(customerId: string): GetParents {
  return {
    customerId,
    payload: {
      client: 'customerProfile',
      request: {
        method: 'GET',
        url: `/customers/${customerId}/parents`
      }
    },
    type: GET_PARENTS
  };
}

export function isGetChildrenSuccess(action: AnyAction): action is GetChildrenSuccessAction {
  return action.type === GET_CHILDREN_SUCCESS;
}

export function isAddChildrenSuccess(action: AnyAction): action is AddChildrenSuccessAction {
  return action.type === ADD_CHILDREN_SUCCESS;
}

export function isGetParentsSuccess(action: AnyAction): action is GetParentsSuccessAction {
  return action.type === GET_PARENTS_SUCCESS;
}
