import DeviceHub from '@material-ui/icons/DeviceHub';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {AnyAction} from 'redux';
import FormPage from '../components/formPage/FormPage';
import {getCustomers} from '../customers/actions';
import {AxiosAction, AxiosDispatch} from '../middleware/axios';
import {createSnackbar} from '../notifications/actions';
import {getHub, isUpdateHubSuccess, updateHub} from './actions';
import HubForm, {SubmitProps} from './form/HubForm';
import messages from './translations';

export interface Props extends RouteComponentProps<{hubId: string; customerId?: string; }> {
  dispatch: AxiosDispatch;
}

export const EditHub = (props: Props) => {
  const {match, dispatch, history} = props;
  const {formatMessage} = useIntl();

  const hubId = match.params.hubId;

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHub(hubId));
  }, [dispatch, hubId]);

  const onSubmit = ({
    serialNumber,
    displayName,
    customerId,
    bluetoothMacAddress,
    ethernetMacAddress,
    wifiMacAddress,
    zigbeeMacAddress
  }: SubmitProps): Promise<AnyAction> => {
    return dispatch(updateHub({
      bluetoothMacAddress,
      customerId,
      displayName,
      ethernetMacAddress,
      serialNumber,
      wifiMacAddress,
      zigbeeMacAddress
    }, history))
        .then((action: AxiosAction) => {
          if (isUpdateHubSuccess(action)) {
            dispatch(createSnackbar({
              message: formatMessage(messages['hubs.update.success']),
              options: {
                variant: 'success'
              }
            }));
          } else {
            dispatch(createSnackbar({
              message: formatMessage(messages['hubs.update.error']),
              options: {
                variant: 'error'
              }
            }));
          }

          return action;
        });
  };

  return (
      <FormPage pageTitle={formatMessage(messages['hubs.update'])} pageIcon={<DeviceHub/>}>
        <HubForm onSubmit={onSubmit} hubSerialNumber={hubId} />
      </FormPage>
  );
};

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(undefined, mapDispatchToProps)(EditHub);
