const config = {
  auth: {
    accessTokenValidity: Number(process.env.REACT_APP_ACCESS_TOKEN_MIN_VALIDITY_SECS) || 30,
    clientId: process.env.REACT_APP_OAUTH2_CLIENT_ID,
    realm: process.env.REACT_APP_OAUTH2_REALM,
    url: process.env.REACT_APP_OAUTH2_API_URL
  },
  backend: {
    url: process.env.REACT_APP_API_URL
  },
  customerProfile: {
    url: process.env.REACT_APP_CUSTOMER_PROFILE_API_URL
  },
  features: {
    customerRelationships: process.env.REACT_APP_FT_CUSTOMER_RELATIONSHIPS === 'true'
  },
  gitBranch: process.env.REACT_APP_BRANCH_NAME || 'branch-name',
  gitCommit: process.env.REACT_APP_GIT_COMMIT || 'commit-id',
  help: {
    url: process.env.REACT_APP_HELP_URL || 'https://www.checkit.net/help'
  },
  updateServer: {
    url: process.env.REACT_APP_UPDATE_SERVER_API_URL
  },
  version: process.env.REACT_APP_VERSION,
  workManagement: {
    url: process.env.REACT_APP_WORK_MANAGEMENT_API_URL
  }
};

export default config;
