import {History} from 'history';
import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';
import {Hub} from './state';

const GET_HUB = 'HUBS.GET';
const GET_HUBS = 'HUBS.GET_ALL';
const CREATE_HUB = 'HUBS.CREATE';
const UPDATE_HUB = 'HUBS.UPDATE';
const GET_HUBS_SUCCESS = GET_HUBS + '_SUCCESS';
const GET_HUB_SUCCESS = GET_HUB + '_SUCCESS';
const CREATE_HUB_SUCCESS = CREATE_HUB + '_SUCCESS';
const UPDATE_HUB_SUCCESS = UPDATE_HUB + '_SUCCESS';

export interface HubDto {
  serialNumber: string;
  displayName: string;
  customerId: string;
  wifiMacAddress: string | null;
  ethernetMacAddress: string | null;
  bluetoothMacAddress: string | null;
  zigbeeMacAddress: string | null;
}

export interface GetHubsAction extends AxiosAction {
  type: typeof GET_HUBS;
}

export interface GetHubAction extends AxiosAction {
  type: typeof GET_HUB;
}

export interface UpdateHubAction extends AxiosAction {
  type: typeof UPDATE_HUB;
}

export interface GetHubsSuccessAction extends AxiosSuccessAction<Hub[]> {
  type: typeof GET_HUBS_SUCCESS;
}

export interface GetHubSuccessAction extends AxiosSuccessAction<Hub> {
  type: typeof GET_HUB_SUCCESS;
}

export interface UpdateHubSuccessAction extends AxiosSuccessAction<Hub> {
  type: typeof UPDATE_HUB_SUCCESS;
}

export interface CreateHubAction extends AxiosAction {
  type: typeof CREATE_HUB;
}

export interface CreateHubSuccessAction extends AxiosSuccessAction<Hub> {
  type: typeof CREATE_HUB_SUCCESS;
}

export function getHubs(): GetHubsAction {
  return {
    payload: {
      client: 'backendAdmin',
      request: {
        url: '/hubs'
      }
    },
    type: GET_HUBS
  };
}

export function getHub(hubId: string): GetHubAction {
  return {
    payload: {
      client: 'backendAdmin',
      request: {
        url: `/hubs/${hubId}`
      }
    },
    type: GET_HUB
  };
}

export function createHub(hub: HubDto, history: History): CreateHubAction {
  return {
    onSuccess: () => dispatch => dispatch(() => history.push('/hubs')),
    payload: {
      client: 'backendAdmin',
      request: {
        data: {
          ...hub,
          displayName: hub.displayName.trim()
        },
        method: 'POST',
        url: '/hubs'
      }
    },
    type: CREATE_HUB
  };
}

export function updateHub(hub: HubDto, history: History): UpdateHubAction {
  return {
    onSuccess: () => dispatch => dispatch(() => history.push('/hubs')),
    payload: {
      client: 'backendAdmin',
      request: {
        data: hub,
        method: 'PUT',
        url: `/hubs/${hub.serialNumber}`
      }
    },
    type: UPDATE_HUB
  };
}

export function isGetHubsSuccess(action: AnyAction): action is GetHubsSuccessAction {
  return action.type === GET_HUBS_SUCCESS;
}

export function isGetHubSuccess(action: AnyAction): action is GetHubSuccessAction {
  return action.type === GET_HUB_SUCCESS;
}

export function isCreateHubSuccess(action: AnyAction): action is CreateHubSuccessAction {
  return action.type === CREATE_HUB_SUCCESS;
}

export function isUpdateHubSuccess(action: AnyAction): action is UpdateHubSuccessAction {
  return action.type === UPDATE_HUB_SUCCESS;
}
