import { ActionDeleteIcon } from '@checkit/react-components/components/Icons';
import { Card, CardContent, CardHeader, List, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormPanel } from '../../components/form-panel/FormPanel';
import { getCustomerFeatures } from '../../customer-features/actions';
import { addChildren, getChildren, getParents, isAddChildrenSuccess } from '../../customer-relationship/actions';
import { AxiosDispatch } from '../../middleware/axios';
import { createSnackbar } from '../../notifications/actions';
import { State } from '../../state';
import { CustomersState } from '../state';
import messages from '../translations';
import AddChildForm from './AddChildForm';

interface OwnProps {
  customerId: string;
}

interface Props extends OwnProps {
  isParent: boolean;
  children: string[];
  parents: string[];
  customersById: CustomersState['byId'];
  dispatch: AxiosDispatch;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  highlight: {
    backgroundColor: yellow['200']
  },
  wrapper: {
    width: '100%'
  }
}));

export const CustomerRelationships = (props: Props) => {
  const { children, customerId, customersById, dispatch, isParent, parents } = props;

  const [ newChildren, setNewChildren ] = useState<string[]>([]);
  const [ formSubmitting, setFormSubmitting ] = useState(false);

  useEffect(() => {
    dispatch(getCustomerFeatures(customerId));
    dispatch(getChildren(customerId));
    dispatch(getParents(customerId));
  }, [dispatch, customerId]);

  const { formatMessage } = useIntl();
  const classes = useStyles();

  const onAddChild = useCallback((childCustomerId: string) => {
    setNewChildren([childCustomerId, ...newChildren]);
  }, [ newChildren ]);

  const onRemoveChild = (childCustomerId: string) => ((_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setNewChildren(newChildren.filter(c => c !== childCustomerId));
  });

  const onSave = useCallback(async () => {
    setFormSubmitting(true);

    const resultingAction = await dispatch(addChildren(customerId, newChildren.map(c => ({customerId: c}))));

    setFormSubmitting(false);

    if (isAddChildrenSuccess(resultingAction)) {
      setNewChildren([]);

      dispatch(createSnackbar({
        message: formatMessage(messages['customers.addChildren.success']),
        options: {
          variant: 'success'
        }
      }));
    } else {
      dispatch(createSnackbar({
        message: formatMessage(messages['customers.addChildren.error']),
        options: {
          variant: 'error'
        }
      }));
    }
  }, [ customerId, dispatch, newChildren, formatMessage ]);

  return (
    <FormPanel
      heading={formatMessage(messages['customers.relationship'])}
      secondaryHeading={props.isParent ?
        formatMessage(messages['customers.relationship.parent']) :
        formatMessage(messages['customers.relationship.child'])}
      name="relationships"
      initialExpanded={false}
    >
      <div className={classes.wrapper}>
        <Card
          raised={true}
          className={classes.card}
        >
          <CardHeader
            titleTypographyProps={{ variant: 'h6' }}
            title={props.isParent ?
              formatMessage(messages['customers.relationship.children']) :
              formatMessage(messages['customers.relationship.parents'])}
          />
          <CardContent>
            {(children.length > 0 || newChildren.length > 0) ? (
              <>
                <List>
                  {newChildren.map(child => (
                    <ListItem key={child}>
                      <Link to={`/customers/${child}`} target={'_blank'} className={classes.highlight}>
                        {customersById[child] ? customersById[child].name : child}
                      </Link>
                      <IconButton
                        onClick={onRemoveChild(child)}
                        title={formatMessage(messages['customers.removeChild'])}
                      >
                        <ActionDeleteIcon/>
                      </IconButton>
                    </ListItem>
                  ))}
                  {children.map(child => (
                    <ListItem key={child}>
                      <Link to={`/customers/${child}`} target={'_blank'}>
                        {customersById[child] ? customersById[child].name : child}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (parents.length > 0) ?
            (
              parents.map(parent => (
                <ListItem key={parent}>
                  <Link to={`/customers/${parent}`} target={'_blank'}>
                    {customersById[parent] ? customersById[parent].name : parent}
                  </Link>
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary" component="p">
                {isParent ?
                  formatMessage(messages['customers.noChildren']) :
                  formatMessage(messages['customers.noParents'])
                }
              </Typography>
            )}
          </CardContent>
        </Card>
        {isParent ?
          (
            <AddChildForm
              customerId={customerId}
              newChildren={newChildren}
              onAddChild={onAddChild}
              onSave={onSave}
              formSubmitting={formSubmitting}
            />
          ) : null
        }
      </div>
    </FormPanel >
  );
};

const mapStateToProps = (state: State, props: OwnProps) => ({
  children: state.customerRelationship.children[props.customerId] || [],
  customersById: state.customers.byId,
  isParent: state.customerFeatures.features[props.customerId] &&
    state.customerFeatures.features[props.customerId].includes('LIBRARIES'),
  parents: state.customerRelationship.parents[props.customerId] || []
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRelationships);
