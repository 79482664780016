import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectProps } from '@material-ui/core/Select';
import { Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Cancel from '@material-ui/icons/Cancel';
import Save from '@material-ui/icons/Save';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import { compose, pathOr, prop, sortBy, toLower } from 'ramda';
import React, { ChangeEvent, createRef, RefObject, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { FormPanel } from '../../components/form-panel/FormPanel';
import MaterialUiLink from '../../components/MaterialUiLink';
import { Customer, CustomersState, CustomerType } from '../../customers/state';
import { State } from '../../state';
import { appMessages } from '../../translations';
import { Hub } from '../state';
import messages from '../translations';

export interface SubmitProps {
  serialNumber: string;
  customerId: string;
  displayName: string;
  wifiMacAddress: string | null;
  ethernetMacAddress: string | null;
  bluetoothMacAddress: string | null;
  zigbeeMacAddress: string | null;
}

interface OwnProps {
  hubSerialNumber: string | null;
  onSubmit: (data: SubmitProps) => Promise<AnyAction>;
}

export interface Props extends OwnProps {
  customers: CustomersState;
  hub: Hub | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selectFormControl: {
    margin: theme.spacing(1, 0)
  },
  toolBar: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
}));

const MAX_NAME_LENGTH = 200;

const sortByName = sortBy(compose(toLower, prop('name')));

export const HubForm = (props: Props) => {
  const { customers, hub } = props;
  const formRef = createRef() as RefObject<ValidatorForm>;

  const { formatMessage } = useIntl();

  const [serialNumber, setSerialNumber] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [name, setName] = useState('');
  const [wifiMacAddress, setWifiMacAddress] = useState('');
  const [ethernetMacAddress, setEthernetMacAddress] = useState('');
  const [bluetoothMacAddress, setBluetoothMacAddress] = useState('');
  const [zigbeeMacAddress, setZigbeeMacAddress] = useState('');

  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    if (hub) {
      setName(hub.displayName);
      setSerialNumber(hub.serialNumber);
      setCustomerId(hub.customerId);
      setWifiMacAddress(hub.wifiMacAddress || '');
      setEthernetMacAddress(hub.ethernetMacAddress || '');
      setBluetoothMacAddress(hub.bluetoothMacAddress || '');
      setZigbeeMacAddress(hub.zigbeeMacAddress || '');
    }
  }, [hub]);

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const onChangeSerialNumber = (event: ChangeEvent<HTMLInputElement>) => setSerialNumber(event.target.value);
  const onChangeWifiMacAddress = (event: ChangeEvent<HTMLInputElement>) => setWifiMacAddress(event.target.value);
  const onChangeEthernetMacAddress = (event: ChangeEvent<HTMLInputElement>) =>
    setEthernetMacAddress(event.target.value);
  const onChangeBluetoothMacAddress = (event: ChangeEvent<HTMLInputElement>) =>
    setBluetoothMacAddress(event.target.value);
  const onChangeZigbeeMacAddress = (event: ChangeEvent<HTMLInputElement>) => setZigbeeMacAddress(event.target.value);
  const onChangeCustomerId: SelectProps['onChange'] = (event) => setCustomerId(event.target.value as string);

  const onSubmit = async (event: any) => {
    let isFormValid = false;

    event.preventDefault();

    // Form validation
    if (formRef.current) {
      isFormValid = await formRef.current.isFormValid(false);
    }

    if (isFormValid) {
      setFormSubmitting(true);

      const resultingAction = await props.onSubmit({
        bluetoothMacAddress,
        customerId,
        displayName: name,
        ethernetMacAddress,
        serialNumber,
        wifiMacAddress,
        zigbeeMacAddress
      });

      // If the action was successful, the component won't be mounted and we get a warning
      if (resultingAction.type.indexOf('_SUCCESS') === -1) {
        setFormSubmitting(false);
      }
    }
  };

  const classes = useStyles();

  const SaveIcon = () => formSubmitting
    // Size 12 keeps the indicator manageable
    ? <CircularProgress className={clsx(classes.leftIcon)} color="inherit" size={12} />
    : <Save className={clsx(classes.leftIcon)} color="inherit" />;

  const macAddressValidator = 'matchRegexp:^(?:(?:(?:[0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2})|(?:(?:[0-9A-Fa-f]{2}-){5}[0-9A-Fa-f]{2}))$';

  const sortedCustomers = sortByName(Object.values(customers.byId))
    .filter(c => c.type !== CustomerType.DORMANT);

  return (
    <ValidatorForm ref={formRef} onSubmit={onSubmit}>
      <FormPanel heading={formatMessage(messages['hubs.hub'])} name={'hub'} initialExpanded={true}>
        <TextValidator
          fullWidth={true}
          id="serialNumber"
          label={formatMessage(messages['hubs.serialNumber'])}
          name="serialNumber"
          autoFocus={true}
          value={serialNumber}
          withRequiredValidator={true}
          validators={['required', 'matchRegexp:^(?:[0-9]{4}-){3}[0-9]{4}$']}
          placeholder="1234-1234-1234-1234"
          errorMessages={[
            formatMessage(messages['hubs.serialNumber.required']),
            formatMessage(messages['hubs.serialNumber.pattern'])
          ]}
          InputProps={{
            disabled: !!hub
          }}
          onChange={onChangeSerialNumber}
        />
        <TextValidator
          fullWidth={true}
          id="name"
          label={formatMessage(messages['hubs.name'])}
          name="name"
          value={name}
          withRequiredValidator={true}
          validators={['required', 'trim', `maxStringLength:${MAX_NAME_LENGTH}`]}
          errorMessages={[
            formatMessage(messages['hubs.name.required']),
            formatMessage(messages['hubs.name.nonBlank']),
            formatMessage(messages['hubs.name.maxLength'], { maxLength: MAX_NAME_LENGTH })
          ]}
          onChange={onChangeName}
        />
        {!hub ? (
          <TextValidator
            select={true}
            className={classes.selectFormControl}
            name="customerId"
            value={customerId}
            withRequiredValidator={true}
            fullWidth={true}
            label={formatMessage(messages['hubs.customer'])}
            validators={['required']}
            errorMessages={[formatMessage(messages['hubs.customer.required'])]}
            onChange={onChangeCustomerId}
            inputProps={{
              id: 'select-customer-id'
            }}
          >
            <MenuItem>{formatMessage(messages['hubs.customer.select'])}</MenuItem>
            {
              sortedCustomers.map((c: Customer) => (
                <MenuItem key={c.customerId} value={c.customerId}>
                  {c.name}
                </MenuItem>
              ))
            }
          </TextValidator>
        ) : (
          <TextValidator
            className={classes.selectFormControl}
            name="customerId"
            value={pathOr('', ['byId', customerId, 'name'], customers)}
            disabled={true}
            fullWidth={true}
            label={formatMessage(messages['hubs.customer'])}
          />
        )}
      </FormPanel>
      <FormPanel heading={formatMessage(messages['hubs.macAddresses'])} name="macAddresses" initialExpanded={false}>
        <TextValidator
          fullWidth={true}
          id="wifiMacAddress"
          label={formatMessage(messages['hubs.wifiMacAddress'])}
          name="wifiMacAddress"
          value={wifiMacAddress}
          validators={[macAddressValidator]}
          errorMessages={[formatMessage(messages['hubs.validMacAddress'])]}
          onChange={onChangeWifiMacAddress}
          placeholder="12-12-12-12-12-12"
        />
        <TextValidator
          fullWidth={true}
          id="ethernetMacAddress"
          label={formatMessage(messages['hubs.ethernetMacAddress'])}
          name="ethernetMacAddress"
          value={ethernetMacAddress}
          validators={[macAddressValidator]}
          errorMessages={[formatMessage(messages['hubs.validMacAddress'])]}
          onChange={onChangeEthernetMacAddress}
          placeholder="12-12-12-12-12-12"
        />
        <TextValidator
          fullWidth={true}
          id="bluetoothMacAddress"
          label={formatMessage(messages['hubs.bluetoothMacAddress'])}
          name="bluetoothMacAddress"
          value={bluetoothMacAddress}
          validators={[macAddressValidator]}
          errorMessages={[formatMessage(messages['hubs.validMacAddress'])]}
          onChange={onChangeBluetoothMacAddress}
          placeholder="12-12-12-12-12-12"
        />
        <TextValidator
          fullWidth={true}
          id="zigbeeMacAddress"
          label={formatMessage(messages['hubs.zigbeeMacAddress'])}
          name="zigbeeMacAddress"
          value={zigbeeMacAddress}
          validators={[macAddressValidator]}
          errorMessages={[formatMessage(messages['hubs.validMacAddress'])]}
          onChange={onChangeZigbeeMacAddress}
          placeholder="12-12-12-12-12-12"
        />
      </FormPanel>
      <Toolbar className={classes.toolBar}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          component={MaterialUiLink}
          to="/customers"
        >
          <Cancel className={clsx(classes.leftIcon, classes.iconSmall)} />
          {formatMessage(appMessages['app.cancel'])}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={formSubmitting}
        >
          <SaveIcon />
          {formatMessage(appMessages['app.save'])}
        </Button>
      </Toolbar>
    </ValidatorForm>
  );
};

const mapStateToProps = (state: State, props: OwnProps) => ({
  customers: state.customers,
  hub: props.hubSerialNumber ? state.hubs.bySerialNumber[props.hubSerialNumber] : null
});

export default connect(mapStateToProps)(HubForm);
