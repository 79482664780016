import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { Cancel, Save } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { appMessages } from '../../translations';
import MaterialUiLink from '../MaterialUiLink';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  formGroup: {
    width: '100%'
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  selectFormControl: {
    width: '100%'
  },
  toolBar: {
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
}));

interface Props {
  formSubmitting: boolean;
  saveId?: string;
  onSave?: () => Promise<void>;
}

export const SaveToolbar = ({ formSubmitting, saveId, onSave }: Props) => {

  const classes = useStyles();
  const i18n = useIntl();

  const SaveIcon = () => formSubmitting
    // Size 12 keeps the indicator manageable
    ? <CircularProgress className={clsx(classes.leftIcon)} color="inherit" size={12} />
    : <Save className={clsx(classes.leftIcon)} color="inherit" />;

  return (
    <Toolbar className={classes.toolBar}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        component={MaterialUiLink}
        to="/customers"
      >
        <Cancel className={clsx(classes.leftIcon, classes.iconSmall)} />
        {i18n.formatMessage(appMessages['app.cancel'])}
      </Button>
      <Button
        id={saveId}
        variant="contained"
        color="primary"
        type={onSave ? 'button' : 'submit'}
        onClick={onSave}
        className={classes.button}
        disabled={formSubmitting}
      >
        <SaveIcon />
        {i18n.formatMessage(appMessages['app.save'])}
      </Button>
    </Toolbar>
  );
};
