// The react-app-polyfill imports MUST be the first imports in the file
/* tslint:disable:ordered-imports */

import {MuiThemeProvider} from '@material-ui/core';
import 'date-time-format-timezone';
import 'intl-pluralrules';
import Keycloak from 'keycloak-js';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import 'typeface-roboto';
import App from './App';
import {updateTokenAction} from './auth/actions';
import config from './config';
import * as serviceWorker from './serviceWorker';
import {store} from './store';
import {officeTheme} from './theme';

const keycloak = Keycloak({
  clientId: config.auth.clientId,
  realm: config.auth.realm,
  url: config.auth.url
});

keycloak.init({ onLoad: 'login-required' }).success((authenticated) => {
  if (authenticated && keycloak.token) {
    store.dispatch(updateTokenAction(
      keycloak.logout.bind(keycloak),
      keycloak.updateToken.bind(keycloak),
      () => keycloak.token
    ));

    ReactDOM.render((
        <Provider store={store}>
          <MuiThemeProvider theme={officeTheme}>
            <App/>
          </MuiThemeProvider>
        </Provider>
      ),
      document.getElementById('root')
    );

  } else {
    keycloak.login();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
