import Grid from '@material-ui/core/Grid';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, {ReactElement, ReactNode} from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  titleIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export interface Props {
  children: ReactNode;
  pageTitle: string;
  pageIcon: ReactElement;
}

const FormPage = (props: Props) => {
  const classes = useStyles();
  const { children, pageTitle, pageIcon } = props;

  return (
      <>
        {/* Title row */}
        <Grid item={true} xs={12}>
          {/* Container for centering */}
          <Grid container={true} justify="center">
            <Grid item={true} xs={10} md={8} lg={8}>
              {/* Container within which to center text at xs */}
              <Grid container={true} alignItems="center" className={classes.titleContainer}>
                <Grid item={true} className={classes.titleIcon}>
                  {pageIcon}
                </Grid>
                <Grid item={true}>
                  <Typography component="h1" variant="h5">
                    {pageTitle}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Form row */}
        <Grid item={true} xs={12}>
          {/* Container for centering */}
          <Grid container={true} justify="center">
            <Grid item={true} xs={10} md={8} lg={8}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </>
  );
};

export default FormPage;
