import {DateTime} from 'luxon';
import {always, assoc, compose, concat, evolve, ifElse, indexBy, isNil, join, map, mergeLeft} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {FeatureWithSettingsDto, isGetFeaturesSuccessAction, isGetFeatureSuccessAction} from './actions';
import {Feature, Features, Setting, SettingWithFeatureCode} from './state';

const initialState: Features = {
  allCodes: [],
  byCode: {},
  settingsByCode: {}
};

export const features = combineReducers({
  allCodes,
  byCode,
  settingsByCode
});

function allCodes(state = initialState.allCodes, action: AnyAction): typeof initialState.allCodes {
  if (isGetFeaturesSuccessAction(action)) {
    // The type inferencer fails to conclude both possibilities have a code property of type string
    const data = action.payload.data as Array<{code: string}>;
    return data.map(f => f.code);
  }

  return state;
}

const optionalDateTime = ifElse(isNil, always(null), DateTime.fromISO);
const settingsToCode = ifElse(isNil, always(null), map<Setting, string>(s => s.code));
const addLuxon: (_: any) => any = evolve({
  endDate: optionalDateTime,
  settings: settingsToCode,
  startDate: optionalDateTime
});
const prependFeatureCodeToSettings: (_: any) => any = (f) => evolve({
  settings: ifElse(isNil, always(null), map(concat(f.code + '.')))
}, f);

const indexByCode = indexBy<Feature>(x => x.code);

const parseFeature = compose(prependFeatureCodeToSettings, addLuxon);
const parseAndIndexFeatures = compose(indexByCode, map(parseFeature));

function byCode(state = initialState.byCode, action: AnyAction): typeof initialState.byCode {
  if (isGetFeaturesSuccessAction(action)) {
    return parseAndIndexFeatures(action.payload.data);
  } else if (isGetFeatureSuccessAction(action)) {
    return assoc(action.payload.data.code, parseFeature(action.payload.data), state);
  }

  return state;
}

const addFeatureCode = (featureCode: string) => assoc('featureCode', featureCode);

const indexByFeatureCodeAndCode = indexBy(compose(
    join('.'),
    (s: SettingWithFeatureCode) => [s.featureCode, s.code]
));

const parseSettings = (featureCode: string) => compose(
    indexByFeatureCodeAndCode,
    map<Setting, SettingWithFeatureCode>(addFeatureCode(featureCode))
);

// Settings have a (public) compound PK of (code, featureId), so index
function settingsByCode(state = initialState.settingsByCode, action: AnyAction): typeof initialState.settingsByCode {
  if (isGetFeaturesSuccessAction(action)) {
    const includeSettings = action.meta.previousAction.payload.request.params.includeSettings;

    if (!includeSettings) {
      return state;
    }

    // If we're here then we asked the server to include settings
    const featuresWithSettings = action.payload.data as FeatureWithSettingsDto[];

    return featuresWithSettings
        .map(feature => parseSettings(feature.code)(feature.settings))
        .reduce((acc, settings) => mergeLeft(acc, settings), {});
  } else if (isGetFeatureSuccessAction(action)) {
    const feature = action.payload.data;
    const featureCode = feature.code;
    const settings = feature.settings;

    return parseSettings(featureCode)(settings);
  }

  return state;
}
