import {AnyAction} from 'redux';
import {SettingType} from '../features/state';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';

const GET_CUSTOMER_FEATURES = 'CUSTOMERS.GET_FEATURES';
const GET_CUSTOMER_FEATURES_SUCCESS = GET_CUSTOMER_FEATURES + '_SUCCESS';

export interface CustomerFeatureDto {
  code: string;
  settings: CustomerSettingDto[];
}

export interface CustomerSettingDto {
  code: string;
  type: SettingType;
  value: string;
}

export interface GetCustomerFeaturesAction extends AxiosAction {
  customerId: string;
  type: typeof GET_CUSTOMER_FEATURES;
}

export interface GetCustomerFeaturesSuccessAction
    extends AxiosSuccessAction<CustomerFeatureDto[], GetCustomerFeaturesAction> {
  type: typeof GET_CUSTOMER_FEATURES_SUCCESS;
}

export function getCustomerFeatures(customerId: string): GetCustomerFeaturesAction {
  return {
    customerId,
    payload: {
      client: 'customerProfile',
      request: {
        method: 'GET',
        url: `/customers/${customerId}/features`
      }
    },
    type: GET_CUSTOMER_FEATURES
  };
}

export function isGetCustomerFeaturesSuccess(action: AnyAction): action is GetCustomerFeaturesSuccessAction {
  return action.type === GET_CUSTOMER_FEATURES_SUCCESS;
}
