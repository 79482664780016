import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';
import {Feature, Setting} from './state';

const GET_FEATURES = 'FEATURES.GET_ALL';
const GET_FEATURES_SUCCESS = GET_FEATURES + '_SUCCESS';
const GET_FEATURE = 'FEATURES.GET_ONE';
const GET_FEATURE_SUCCESS = GET_FEATURE + '_SUCCESS';

export type FeatureDto = Omit<Feature, 'settings' | 'startDate' | 'endDate'> & {
  endDate: string | null,
  startDate: string | null
};

export type FeatureWithSettingsDto = FeatureDto & {
  settings: Setting[];
};

export interface GetFeaturesAction extends AxiosAction {
  type: typeof GET_FEATURES;
}

export interface GetFeaturesSuccessAction extends AxiosSuccessAction<
    FeatureDto[] | FeatureWithSettingsDto[],
    GetFeaturesAction
> {
  type: typeof GET_FEATURES_SUCCESS;
}

export interface GetFeatureAction extends AxiosAction {
  type: typeof GET_FEATURE;
}

export interface GetFeatureSuccessAction extends AxiosSuccessAction<FeatureWithSettingsDto> {
  type: typeof GET_FEATURE_SUCCESS;
}

export function getFeatures(includeSettings = false): GetFeaturesAction {
  return {
    payload: {
      client: 'customerProfile',
      request: {
        params: {
          includeSettings
        },
        url: '/admin/features'
      }
    },
    type: GET_FEATURES
  };
}

export function getFeature(code: string): GetFeatureAction {
  return {
    payload: {
      client: 'customerProfile',
      request: {
        url: `/admin/features/${code}`
      }
    },
    type: GET_FEATURE
  };
}

export function isGetFeaturesSuccessAction(action: AnyAction): action is GetFeaturesSuccessAction {
  return action.type === GET_FEATURES_SUCCESS;
}

export function isGetFeatureSuccessAction(action: AnyAction): action is GetFeatureSuccessAction {
  return action.type === GET_FEATURE_SUCCESS;
}
